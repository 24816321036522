

$.fn.isInViewport = function () {
    if ($(this).length) {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    }
};

$.fn.getThisFromViewport = function () {
    if ($(this).length) {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();


        if(elementBottom > viewportTop && elementTop < viewportBottom){
            return this;
        }
    }
};


$(window).on('load',function(){
    $.each(document.images, function(){
        var this_image = this;
        var src = $(this_image).attr('src') || '' ;
        if(!src.length > 0){
            //this_image.src = options.loading; // show loading
            var lsrc = $(this_image).attr('data-src') || '' ;
            if(lsrc.length > 0){
                var img = new Image();
                img.src = lsrc;
                $(img).on('load', function() {
                    this_image.src = this.src;
                });
            }
        }
    });
});



$(document).ready(function () {
    $(window).on('resize scroll load', function () {

        if ($(".progress-animation").isInViewport() && ($(".progress-animation").hasClass('animation-done') == false)) {
            var a = 0;
            $(".progress-animation").addClass('animation-done');

            $('.percent').each(function () {
                var num = $(this)
                    , countTo = num.attr('data-percent');
                $({
                    countNum: num.text()
                }).animate({
                    countNum: countTo
                }, {
                    duration: 3000
                    , easing: 'swing'
                    , step: function () {
                        num.text(Math.floor(this.countNum));
                    }
                    , complete: function () {
                        num.text(this.countNum);
                        //alert('finished');
                    }
                });
            });
        }

    });
});

$(window).on('load', function () {

    /**
     *  VIDEO PLAYER
     **/
    $('.video-player').removeClass('loading');
    $('.video-player').addClass('play');
    $('.video-player').click(function () {

        /** VIDEO CONTAINER **/
        var vcont = $(this);
        var videoID = $(this).find('video');
        var video = $(this).find('video').get(0);

        if (video.paused) {
            video.play();
            vcont.removeClass('play');
            videoID.removeClass('video-paused');
        } else {
            video.pause();
            vcont.removeClass('pause');
            vcont.addClass('play');

            videoID.addClass('video-paused');
        }

    }).hover(function () {
        var vcont = $(this);
        var video = $(this).find('video').get(0);
        if (video.paused) {
            vcont.removeClass('pause');
            vcont.addClass('play');
        } else {
            vcont.removeClass('play');
            vcont.addClass('pause');
        }
    }, function () {
        var vcont = $(this);
        var video = $(this).find('video').get(0);

        if (video.paused) {
            vcont.removeClass('pause');
        } else {
            vcont.removeClass('play');
            vcont.removeClass('pause');

        }

    });
});


$(window).on('load resize scroll', function () {
    if ($(".video-autoplay-inViewport").length) {

        if ($(".video-autoplay-inViewport").isInViewport() && $(".video-autoplay-inViewport").hasClass("video-paused") == false) {

            var video_element = $(".video-autoplay-inViewport").getThisFromViewport();

            $(video_element)[0].play();
            $('.video-player').removeClass('play');

        } else {
            $('video')[0].pause();
            $('#play_video').html('Play Video');
        }
    }
})




